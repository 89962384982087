<template>
  <v-container fluid>
    <v-radio-group v-model="locale" row>
      <v-radio v-for="(locale, index) in localesDictionary" :key="index" :label="locale.name" :value="locale.value"></v-radio>
    </v-radio-group>
    <template v-if="isEventExist">
      <v-row>
        <v-col cols="12">
          <div class="custom-field">
            <span>ID:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoEvent.id }}</span>
          </div>
          <div class="custom-field">
            <span>Назва {{ additionalInfoEvent.packet_event_type | getPackageDesc2 }}:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoEvent.name[$currentLocale] }}</span>
          </div>
          <div class="custom-field">
            <span>Код {{ additionalInfoEvent.packet_event_type | getPackageDesc2 }}:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoEvent.event_code }}</span>
          </div>
          <div class="custom-field">
            <span>Назва групи {{ additionalInfoEvent.packet_event_type | getPackageDesc2 }}:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoSelectedEventType.name[$currentLocale] }}</span>
          </div>
          <div class="custom-field">
            <span>Код групи {{ additionalInfoEvent.packet_event_type | getPackageDesc2 }}:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoSelectedEventType.code }}</span>
          </div>
          <div class="custom-field">
            <span>Протокол:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoEvent.protocol }}</span>
          </div>
          <div class="custom-field">
            <span>Тип пристрою:</span>
            <span class="font-weight-medium ml-2">
              {{ getDeviceTitle(additionalInfoEvent.device_type) }} ({{ getMachineDesc(additionalInfoEvent.device_type) }})
            </span>
          </div>
          <div class="custom-field">
            <span>Пояснення:</span>
            <span class="font-weight-medium ml-2">
              {{ additionalInfoEvent.short_description[locale] }}
            </span>
          </div>
          <div v-if="additionalInfoEvent.packet_event_type === 1 && additionalInfoEvent.colour" class="custom-field">
            <span>Колір статуса:</span>
            <input class="ml-2" type="color" :value="additionalInfoEvent.colour" disabled style="width: 32px; height: 16px" />
          </div>
        </v-col>
      </v-row>
    </template>

    <v-container fluid>
      <div class="d-flex justify-end mt-7">
        <v-btn @click="$router.go(-1)" class="mr-2" small>Назад</v-btn>
        <v-btn @click="navigateToInfoEventConstructor" color="primary" small>Редагувати</v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import ConvertPackagesTypes from '@/mixins/convertPackagesTypes'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AdditionalInfoEventLocaleCard',

  mixins: [ConvertDevicesTypes, ConvertPackagesTypes],

  props: {
    additionalInfoEvent: {
      type: Object,
      required: true,
      default: () => {},
    },
    additionalInfoSelectedEventType: {
      required: true,
      default: null,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    locale: '',
    loading: false,
  }),

  computed: {
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    ...mapState('dictionaries', ['localesDictionary']),
    ...mapGetters('profile', ['getCurrentUserLocale']),

    isEventExist() {
      return !isObjectEmpty(this.additionalInfoEvent)
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    eventTypeId() {
      return this.$route.params.eventTypeId
    },
    eventId() {
      return this.$route.params.eventId
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    initialize() {
      this.locale = this.getCurrentUserLocale
    },

    navigateToInfoEventConstructor() {
      this.$router.push({
        // path: `/spa/company/${this.currentCompanyId}/${this.ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST}/${this.eventTypeId}/${this.ROUTER_LINKS.INFO_CONSTRUCT_EVENT}`,
        name: 'InfoConstructEvent',
        params: { id: this.currentCompanyId.toString(), eventTypeId: this.eventTypeId.toString() },
        query: { eventId: this.eventId },
        hash: '#edit',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 600px !important;
}
</style>
